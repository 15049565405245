.about__container {
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        column-gap: 4rem;
}

.about__img {
    width: 500px;
    border-radius: 1.5rem;
    justify-self: center;
    height: 375px;
}

.about__description{
    padding: 0 4rem 0 0;
    margin-bottom: var(--mb-2-5);
}

@media screen and (max-width: 992px) {
    .about__container {
        grid-template-columns:  1fr;
        row-gap: 2.5rem;
         }
    .about__img {
            width: 350px;
        }
    .about__data{
        text-align: center;
    }
    .about__description{
        padding: 0 5rem;
        margin-bottom: 2rem;
    }
        
  }

@media screen and (max-width: 576px){
    .about__description{
        padding: 0;
    }
}
 
@media screen and (max-width: 350px){
    .about__description{
        padding: 0;
    }
}