.qualification__container{
    max-width: 768px;
}

.qualification__sections{
    display: grid;
    grid-template-columns: 0.5fr;
    justify-content: center;
}

.qualification__data{
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 2.5rem;
}

.qualification__title{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.qualification__subtitle{
    display: inline-block;
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-1);
}

.qualification__calendar{
    font-size: var(--small-font-size);
}

.qualification__rounder{
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: var(--text-color);
    border-radius: 50%;
}
.qualification__line{
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--text-color);
    transform: translate(6px,-7px);
}

@media screen and (max-width: 992px) {
    .qualification__container{
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 768px){
    .qualification__container{
        margin-left: var(--mb-1-5);
        margin-right: var(--mb-1-5);
    }
}

@media screen and (max-width: 576px){
    .qualification__sections{
        grid-template-columns: initial;
    }
    
}

@media screen and (max-width: 350px){
    .qualification__data{
        gap: 0.5rem;
    }
}
