.home__container{
    row-gap: 7rem;

}

.home__content{
    grid-template-columns: 116px repeat(2, 1fr);
    padding-top: 5.5rem;
    column-gap: 2rem;
    align-items: center;
}

.home__social{
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
}

.home__Social-icon{
    font-size: 1.25rem;
    color: var(--title-color);
}

.home__title{
    font-size: 2.8rem;
    margin-bottom: var(--mb-0-25);
}
.home__Social-icon:hover{
       color: var(--title-color-dark);
}
.home__hand{
    width: 38px;
    height: 38px;
    margin-left: 0.4rem;
}
.home__description{
    max-width: 400px;
    margin-bottom: var(--mb-3);
}


.animated_text {
    display: inline-block;
    font-weight: bolder;
  }
  
  .animated_text span {
    opacity: 0;
    transform: translateY(10px);
    display: inline-block;
    transition: opacity 1s ease, transform 1s ease;
  }
  
  .animated_text span.active {
    opacity: 1;
    transform: translateY(0);
  }


.home__img{
    background: url(../../assets/abc2.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
    order: 1;
    justify-self: center;
    width: 300px;
    height: 300px;
    animation: profile__animate 4s ease-in-out infinite .8s;
}

@keyframes profile__animate {
    0%{
        border-radius: 60% 40% 30% 70%/ 60% 30% 70% 40%;

    }
    50%{
        border-radius: 30% 60% 70% 40%/ 50% 60% 30% 60%;
        
    }
    100%{
        border-radius: 60% 40% 30% 70%/ 60% 30% 70% 40%;
        
    }
}

.home__scroll{
    margin-left: 9.25rem;
}

.wheel{
    animation: scroll 2s ease infinite;
}

@keyframes scroll {
    0%{
        transform: translateY(0);
    }
    30%{
        transform: translateY(3.75rem);
    }
}

.home__scroll-name{
    color: var(--title-color);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
    margin-left: 2px;
}
.home__scroll-arrow{
    font-size: 1.25rem;
    color: var(--title-color);
}

@media screen and (max-width: 992px) {
     .home__content{
        grid-template-columns: 100px repeat(2 ,1fr);
        column-gap: 1.25rem;
     }  
     .home__hand{
        width: 26px;
        height: 26px;
     }
     .home__description{
        max-width: initial;
        margin-bottom: var(--mb-2-5);
     }
     .home__img{
        width: 250px;
        height: 250px;
        box-shadow: inset 0 0 0 8px rgb(255 255 255 / 30%);
     }
     .home__scroll{
        margin-left: 7.5rem;
     }
}
@media screen and (max-width: 778px) {
    .home__content{
       grid-template-columns: 0.5fr 3fr;
       padding-top: 3.5rem;
    }  
    .home__img{
        order: initial;
        justify-self: initial;
     }
     .home__data{
        grid-column: 1/3;
     }
     .home__img{
        width: 200px;
        height: 200px;
        box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
     }
    .home__hand{
       width: 20px;
       height: 20px;
    }
    .home__description{
       max-width: initial;
       margin-bottom: var(--mb-2-5);
    }
    .home__scroll{
       display: none;
    }
}
