.skills__container{
    grid-template-columns: repeat(1 ,750px);
    justify-content: center;
    border: var(--title-color);
}
.skills__name{
    color: var(--title-color);
    font-size: medium;
    align-items: center;
}
.skills__name{
    display: flex;
    justify-content: center;
    align-items: center;
}
.skills__content{
    background-color: var(--container-color);
    border: 1px solid rgba(0,0,0,0.1);
    padding: 2rem 4rem;
    border-radius: 1.25rem;
}

.skills__group{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 4rem;
    row-gap: 2rem;
    margin-left: 7rem;
    margin-right: 4rem;
}
.skill__data{
    display: grid;
    column-gap: 1.5rem;
    width: 50px;
    flex-wrap: wrap;  
}



@media screen and (max-width: 992px) {
    .skills__container{
        grid-template-columns: repeat(1 ,600px);
        justify-content: center;
        border: var(--title-color);
    }
    .skills__group{
        flex-direction: row;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 576px){
    .skills__container{
        grid-template-columns: repeat(1 ,500px);
        justify-content: center;
        border: var(--title-color);
    }
    .skills__group{
        flex-direction: row;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 350px){
    .skills__container{
        grid-template-columns: repeat(1 ,350px);
        justify-content: center;
        border: var(--title-color);
    }
    .skills__group{
        flex-direction: row;
        flex-wrap: wrap;
    }
}


















/* .progress{
    margin: 0.8rem 0;
    border-radius: 10px;
    height: 16px;
    width: 350px;
    background-color: black;
    overflow: hidden;
}
.progress-bar{
    background: green;
    height: 100%;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.progress-bar.w-90{
    width: 90%;
} */

/* 
.item {
    margin-bottom: 30px;
    text-align: left;
  }
  
  .item-text {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .skill-name {
    font-weight: bold;
  }
  
  .skill-percentage {
    color: #666;
  }
  
  .progress {
    width: 100%;
    height: 20px;
    background-color: #ddd;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #007bff; 
  } 
  */
/* 
.container .skill-box{
    width: 100%;
    margin: 15px 0;
}
.skill-box .title{
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: var(--title-color);
}
.skill-box .skill-bar{
    height: 8px;
    width: 100%;
    border-radius: 6px;
    margin-top: 6px;
    background: rgba(0,0,0,0.1);
}
.skill-bar .skill-per{
    position: relative;
    display: block;
    height: 100%;
    width: 90%;
    border-radius: 6px;
    background: grey;
    animation: progress 0.4s ease-in-out forwards;
    opacity: 0;
}
.skill-per.css{
    width: 70%;
    animation-delay: 0.1s;
}
.skill-per.javascript{
    width: 50%;
    animation-delay: 0.2s;
}
.skill-per.nodejs{
    width: 30%;
    animation-delay: 0.3s;
}
@keyframes progress {
    0%{
        width: 0;
        opacity: 1;
    }
    100%{
        opacity: 1;
    }
}
.skill-per .tooltip{
    position: absolute;
    right: -14px;
    top: -28px;
    font-size: 9px;
    font-weight: 500;
    color: #fff;
    padding: 2px 6px;
    border-radius: 3px;
    background: grey;
    z-index: 1;
}
.tooltip::before{
    content: '';
    position: absolute;
    left: 50%;
    bottom: -2px;
    height: 10px;
    width: 10px;
    z-index: -1;
    background-color: gray;
    transform: translateX(-50%) rotate(45deg);
} */